<template>
    <div v-if="classYMD" style="width: 100%;display: flex;">
        <van-dropdown-menu class="common">
            <van-dropdown-item
                v-model="birth.year"
                :title="birth.year == '' ? this.formValueYear : birth.year"
                :options="yearOption"
                @change="handleBirthChange"
            />
        </van-dropdown-menu>
        <van-dropdown-menu :class="requiredMonth ? 'requiredMonth' : ''" class="common">
            <van-dropdown-item
                v-model="birth.month"
                :title="birth.month == '' ? this.formValueMonth : birth.month"
                :options="monthOption"
                @change="handleBirthChange"
            />
        </van-dropdown-menu>
        <van-dropdown-menu :class="requiredDay ? 'requiredDay' : ''" class="common">
            <van-dropdown-item
                v-model="birth.day"
                :title="birth.day == '' ? this.formValueDay : birth.day"
                :options="dayOption"
                @change="handleBirthChange"
            />
        </van-dropdown-menu>
    </div>
    <div v-else style="width: 100%;display: flex;">
        <van-dropdown-menu :class="requiredDay ? 'requiredDay' : ''" class="common">
            <van-dropdown-item
                v-model="birth.day"
                :title="birth.day == '' ? this.formValueDay : birth.day"
                :options="dayOption"
                @change="handleBirthChange"
            />
        </van-dropdown-menu>
        <van-dropdown-menu :class="requiredMonth ? 'requiredMonth' : ''" class="common">
            <van-dropdown-item
                v-model="birth.month"
                :title="birth.month == '' ? this.formValueMonth : birth.month"
                :options="monthOption"
                @change="handleBirthChange"
            />
        </van-dropdown-menu>
        <van-dropdown-menu class="common">
            <van-dropdown-item
                v-model="birth.year"
                :title="birth.year == '' ? this.formValueYear : birth.year"
                :options="yearOption"
                @change="handleBirthChange"
            />
        </van-dropdown-menu>
    </div>
</template>
  
<script>
import { DropdownMenu, DropdownItem } from "vant";

export default {
    components: {
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem,
    },
    props: {
        birth: {
            type: Object,
            default: () => {
                return {
                    day: "",
                    month: "",
                    year: "",
                };
            },
        },
        requiredDay: {
            type: Boolean,
            default: false,
        },
        requiredMonth: {
            type: Boolean,
            default: false,
        },
        country: {
            type: String,
            default: "",
        },
        classYMD: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            dayOption: [
                { text: "01", value: "01" },
                { text: "02", value: "02" },
                { text: "03", value: "03" },
                { text: "04", value: "04" },
                { text: "05", value: "05" },
                { text: "06", value: "06" },
                { text: "07", value: "07" },
                { text: "08", value: "08" },
                { text: "09", value: "09" },
                { text: "10", value: "10" },
                { text: "11", value: "11" },
                { text: "12", value: "12" },
                { text: "13", value: "13" },
                { text: "14", value: "14" },
                { text: "15", value: "15" },
                { text: "16", value: "16" },
                { text: "17", value: "17" },
                { text: "18", value: "18" },
                { text: "19", value: "19" },
                { text: "20", value: "20" },
                { text: "21", value: "21" },
                { text: "22", value: "22" },
                { text: "23", value: "23" },
                { text: "24", value: "24" },
                { text: "25", value: "25" },
                { text: "26", value: "26" },
                { text: "27", value: "27" },
                { text: "28", value: "28" },
                { text: "29", value: "29" },
                { text: "30", value: "30" },
                { text: "31", value: "31" },
            ],
            monthOption: [
                { text: "01", value: "01" },
                { text: "02", value: "02" },
                { text: "03", value: "03" },
                { text: "04", value: "04" },
                { text: "05", value: "05" },
                { text: "06", value: "06" },
                { text: "07", value: "07" },
                { text: "08", value: "08" },
                { text: "09", value: "09" },
                { text: "10", value: "10" },
                { text: "11", value: "11" },
                { text: "12", value: "12" },
            ],
        };
    },
    computed: {
        yearOption() {
            switch (this.country) {
                case "KR":
                    return Array.from(
                        Array(100),
                        (v, k) => k + new Date().getFullYear() - 14 - 99
                    )
                        .reverse()
                        .map((i) => {
                            return { text: i.toString(), value: i.toString() };
                        });
                case "JPN":
                    return Array.from(
                        Array(100),
                        (v, k) => k + new Date().getFullYear() - 18 - 99
                    )
                        .reverse()
                        .map((i) => {
                            return { text: i.toString(), value: i.toString() };
                        });
                default:
                    return Array.from(
                        Array(100),
                        (v, k) => k + new Date().getFullYear() - 14 - 99
                    )
                        .reverse()
                        .map((i) => {
                            return { text: i.toString(), value: i.toString() };
                        });
            }
        },
        formValueDay() {
            switch (this.country) {
                case "KR":
                    return this.$t("registration.formValueDay");
                case "JPN":
                    return this.$t("jpnRegistration.day");
                default:
                    return this.$t("registration.formValueDay");
            }
        },
        formValueMonth() {
            switch (this.country) {
                case "KR":
                    return this.$t("registration.formValueMonth");
                case "JPN":
                    return this.$t("jpnRegistration.month");
                default:
                    return this.$t("registration.formValueMonth");
            }
        },
        formValueYear() {
            switch (this.country) {
                case "KR":
                    return this.$t("registration.formValueYear");
                case "JPN":
                    return this.$t("jpnRegistration.year");
                default:
                    return this.$t("registration.formValueYear");
            }
        },
    },
    created() {},
    methods: {
        handleBirthChange() {
            this.$emit("change", this.birth);
        },
    },
};
</script>
  
<style lang="less" scoped>
.common {
    margin-left: 10px;
}

/deep/ .requiredDay .van-ellipsis::before {
    position: absolute;
    left: 0px;
    top: 0px;
    color: #ee0a24;
    font-size: 14px;
    content: "*";
    z-index: 100;
}

/deep/ .requiredMonth .van-ellipsis::before {
    position: absolute;
    left: 0px;
    top: 0px;
    color: #ee0a24;
    font-size: 14px;
    content: "*";
    z-index: 100;
}
</style>

  